.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background: url(../src//assets//mbr.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-header::before {
	/* Dodaj ten blok */
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6); /* Kolor przyciemnienia */
}

.App-header p {
	z-index: 5;
}

.App-header a {
	z-index: 5;
}

.App-link {
	color: #ff9966;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

footer {
	background-color: #232323;
}

.App-footer {
	width: 100%;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	color: white;
	font-style: inherit;
	font-size: 1.2em;
}
